import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router";
// config file
const Hospitals = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [stateOptions, setStateOptions] = useState();


  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Practices");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
    setStateData();
  }, []);


  const setStateData = async () => {
    let arr = [];
    if (localStorage.StatesOptions) {
      arr = await JSON.parse(localStorage.StatesOptions);
    } else {
      let a = await fetchMethodRequest('GET', apiCalls.Settings).then(res => {
        if (res.settings[0]?.states?.length) {
          arr = res.settings[0]?.states.map(option => { return { label: option.split('-')[1].trim(), value: option, displayLabel: option } })
        }
      })
    }
    setStateOptions(arr);
  }

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const addCustomValidations = (data) => {
    const { valObj, yup } = data;
    if (valObj['count']) {
      valObj['count'] = yup.number()
        .required('Number of  chairs is required')
        .min(1, 'Chairs must be at least 1'); // Ensuring no 0s are allowed
    }
      return valObj;
  }

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      // {
      //   textAlign: "center",
      //   width: 47,
      //   field: "Check Box",
      //   label: "Check Box",
      //   fieldType: "multiple",
      //   header: "",
      //   selectionMode: "multiple",
      //   show: true,
      //   mobile: true,
      //   displayInSettings: true,
      //   displayinlist: "false",
      //   displayinaddForm: "false",
      //   displayineditForm: "false",
      //   globalSearchField: "false",
      //   isFieldRequired: "false",
      //   tDisplay: true,
      // },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "hospitalName",
        type: "text",
        placeholder: "Practice Name",
        label: "Practice Name",
        width: 150,
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Practice Name",
        derivedValue: "hospitalName=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "hospitalName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "hospitalName",
        showOrHideFields: [],
        fieldName: "hospitalName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
      },
      {
        name: "fullName",
        type: "text",
        placeholder: "Owner Name",
        label: "Owner Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Owner Name",
        derivedValue: "fullName=undefined",
        capitalizeTableText: true,
        sortable: true,
        id: "fullName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "fullName",
        filter: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "fullAddress",
        type: "textarea",
        placeholder: "Address",
        label: "Address",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Address",
        derivedValue: "fullAddress=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "fullAddress",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "fullAddress",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "city",
        type: "text",
        placeholder: "City",
        label: "City",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "City",
        derivedValue: "city=city=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "city",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "state",
        type: "dropDown",
        placeholder: "State",
        label: "State",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "State",
        derivedValue: "state=state=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "state",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        options: stateOptions,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "state",
        showOrHideFields: [],
        fieldName: "state",
        fieldType: "text",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "zipCode",
        type: "number",
        placeholder: "Zip Code",
        label: "Zip Code",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Zip Code",
        derivedValue: "zipCode=zipCode=zipCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "zipCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "zipCode",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phoneNumber",
        type: "number",
        placeholder: "Phone Number",
        label: "Phone Number",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Phone",
        derivedValue: "phoneNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "phoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "phoneNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        width: "150px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Email",
        derivedValue: "email=email=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "hospitalID",
        type: "uniqueField",
        placeholder: "Practice ID",
        label: "Practice ID",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Practice ID",
        derivedValue: "hospitalID=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalID",
        filter: true,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "hospitalID",
        showOrHideFields: [],
        fieldName: "hospitalId",
        fieldType: "text",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "hospitalWebsite",
        type: "WebSite",
        placeholder: "Practice Website",
        label: "Practice Website",
        width: "150px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Practice Website",
        derivedValue: "hospitalWebsite=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalWebsite",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "WebSite",
        textAlign: "left",
        show: true,
        field: "hospitalWebsite",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
     
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const hospitalIDValidation = (yup, form) => {
    return yup.string().test({
      name: 'check-regex',
      test: function (value, { originalValue }) {
        if (form.formType === 'edit' && (!value || value === '')) {
          throw this.createError({ path: 'hospitalID', message: 'Practice ID  is a required field' });
        }
        return true;
      },
    });
  }

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [

      {
        name: "hospitalName",
        type: "text",
        placeholder: "Practice Name",
        label: "Practice Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "hospitalName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "hospitalName",
        fieldType: "text",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },

      {
        name: "firstName",
        type: "text",
        placeholder: "First Name",
        label: "First Name",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue: "firstName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "firstName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "lastName",
        type: "text",
        placeholder: "Last Name",
        label: "Last Name",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue: "lastName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "lastName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "address",
        type: "text",
        placeholder: "Address",
        label: "Address",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue: "address=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "address2",
        type: "text",
        placeholder: "Address2",
        label: "Address2",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "address2=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "address2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "city",
        type: "text",
        placeholder: "City",
        label: "City",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "city=city=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "state",
        type: "dropDown",
        placeholder: "State",
        label: "State",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "state=state=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "state",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        options: stateOptions,
        itemTemplate: true,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "state",
        fieldType: "text",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "zipCode",
        type: "number",
        placeholder: "Zip Code",
        label: "Zip Code",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "zipCode=zipCode=zipCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "zipCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldType:'zipCode',
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        maxLength: config.zipCodeLength,
      },
      {
        name: "phoneNumber",
        type: "number",
        placeholder: "Phone Number",
        label: "Phone",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "phoneNumber=phoneNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "phoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        maxLength: config.phoneNumberLength,
        fieldType: 'phone',
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "email=email=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: true,
      },
      {
        name: "password",
        type: "password",
        placeholder: "Password",
        label: "Password",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue: "password=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "password",
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "password",
        fieldType: "password",
        mobile: true,
        displayInSettings: false,
        isAddFormHidden: false,
        isEditFormHidden: true,
      },
      {
        name: "practiceType",
        type: "dropDown",
        placeholder: "Practice Type",
        label: "Practice Type",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "practiceType=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "practiceType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "General Dentistry", value: "General Dentistry" },
          { label: "Pediatric Dentist or Pedodontist", value: "Pediatric Dentist or Pedodontist" },
          { label: "Endodontist", value: "Endodontist" },
          { label: "Orthodontist", value: "Orthodontist" },
          { label: "Periodontist", value: "Periodontist" },
          { label: "Prosthodontist", value: "Prosthodontist" },
          { label: "Oral and Maxillofacial Surgeon", value: "Oral and Maxillofacial Surgeon" },
        ],
        fieldType: "dropDown",
        isMultiSelect: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "hospitalID",
        type: "uniqueField",
        placeholder: "Practice ID",
        label: "Practice ID",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "hospitalId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalID",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        validationRequired: true,
        customValidation: hospitalIDValidation,
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "hospitalID",
        fieldType: "text",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "count",
        type: "number",
        placeholder: "Maximum Number of Chairs",
        label: "Maximum Number of Chairs",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        derivedValue: "count=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "count",
        displayinaddForm: "true",
        displayineditForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: true,
      },
      {
        name: "paymentTypes",
        type: "dropDown",
        placeholder: "Payment Types",
        label: "Payment Types",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 15,
        derivedValue: "paymentTypes=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "paymentTypes",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Cash", value: "Cash" },
          { label: "Cheque", value: "Cheque" },
          { label: "Paypal", value: "Paypal" },
          { label: "Cardknox", value: "Cardknox" },
          { label: "Credit Card", value: "Credit Card" },
          { label: "CareCredit", value: "CareCredit" },
          { label: "Zelle", value: "Zelle" },
          { label: "Venmo", value: "Venmo" },
          { label: "Cashapp", value: "Cashapp" },
          { label: "EFT", value: "EFT" },
        ],
        fieldType: "dropDown",
        isMultiSelect: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        dependent: [{ Paypal: ["paypalClientId", "paypalClientSecret",] }]
      },
      {
        name: "paypalClientId",
        type: "text",
        placeholder: "Paypal Client ID",
        label: "Paypal Client ID",
        width: 110,
        addFormOrder: 16,
        editFormOrder: 16,
        header: "Paypal Client ID",
        derivedValue: "paypalClientId=undefined",
        capitalizeTableText: false,
        sortable: false,
        id: "paypalClientId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: true,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: false,
        field: "paypalClientId",
        filter: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "paypalClientSecret",
        type: "text",
        placeholder: "Paypal Client Secret",
        label: "Paypal Client Secret",
        width: 110,
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Paypal Client Secret",
        derivedValue: "paypalClientSecret=undefined",
        capitalizeTableText: false,
        sortable: false,
        id: "paypalClientSecret",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: true,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: false,
        field: "paypalClientSecret",
        filter: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "hospitalWebsite",
        type: "WebSite",
        placeholder: "Practice Website",
        label: "Practice Website",
        width: "150px",
        addFormOrder: 18,
        editFormOrder: 18,
        derivedValue: "hospitalWebsite=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalWebsite",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "WebSite",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "hospitalName",
        type: "text",
        placeholder: "Hospital Name",
        label: "Hospital Name",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Hospital Name",
        derivedValue: "hospitalName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "hospitalName",
        showOrHideFields: [],
        fieldName: "hospitalName",
        fieldType: "text",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address",
        type: "text",
        placeholder: "Address",
        label: "Address",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Address",
        derivedValue: "address=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "address",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "address2",
        type: "text",
        placeholder: "Address2",
        label: "Address2",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Address2",
        derivedValue: "address2=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "address2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "address2",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "city",
        type: "text",
        placeholder: "City",
        label: "City",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "City",
        derivedValue: "city=city=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "city",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "state",
        type: "text",
        placeholder: "State",
        label: "State",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "State",
        derivedValue: "state=state=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "state",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "state",
        showOrHideFields: [],
        fieldName: "state",
        fieldType: "text",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "zipCode",
        type: "number",
        placeholder: "Zip Code",
        label: "Zip Code",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Zip Code",
        derivedValue: "zipCode=zipCode=zipCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "zipCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "zipCode",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phoneNumber",
        type: "number",
        placeholder: "Phone Number",
        label: "Phone Number",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Phone Number",
        derivedValue: "phoneNumber=phoneNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "phoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "phoneNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "hospitalDepartments",
        type: "multiSelect",
        placeholder: "Hospital Departments",
        label: "Hospital Departments",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Hospital Departments",
        derivedValue: "hospitalDepartments=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalDepartments",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Cardiology", value: "Cardiology" },
          { label: "Orthopedics", value: "Orthopedics" },
          { label: "Internal medicine", value: "Internal medicine" },
          { label: "Neurology", value: "Neurology" },
          { label: "Urology", value: "Urology" },
          { label: "General surgery", value: "General surgery" },
          { label: "Nephrology", value: "Nephrology" },
          {
            label: "Obstetrics and gynaecology",
            value: "Obstetrics and gynaecology",
          },
          { label: "Radiology", value: "Radiology" },
          { label: "Pediatrics", value: "Pediatrics" },
          { label: "Emergency medicine", value: "Emergency medicine" },
          { label: "Otorhinolaryngology", value: "Otorhinolaryngology" },
          { label: "Ophthalmology", value: "Ophthalmology" },
          {
            label: "Intensive care medicine",
            value: "Intensive care medicine",
          },
          { label: "Surgery", value: "Surgery" },
          { label: "Dermatology", value: "Dermatology" },
          { label: "Anesthesiology", value: "Anesthesiology" },
          { label: "Neurosurgery", value: "Neurosurgery" },
        ],
        fieldType: "multiSelect",
        isMultiSelect: true,
        textAlign: "Center",
        show: true,
        field: "hospitalDepartments",
        showOrHideFields: [],
        label0: "Cardiology",
        value0: "Cardiology",
        label1: "Orthopedics",
        value1: "Orthopedics",
        label2: "Internal medicine",
        value2: "Internal medicine",
        label3: "Neurology",
        value3: "Neurology",
        label4: "Urology",
        value4: "Urology",
        label5: "General surgery",
        value5: "General surgery",
        label6: "Nephrology",
        value6: "Nephrology",
        label7: "Obstetrics and gynaecology",
        value7: "Obstetrics and gynaecology",
        label8: "Radiology",
        value8: "Radiology",
        label9: "Pediatrics",
        value9: "Pediatrics",
        label10: "Emergency medicine",
        value10: "Emergency medicine",
        label11: "Otorhinolaryngology",
        value11: "Otorhinolaryngology",
        label12: "Ophthalmology",
        value12: "Ophthalmology",
        label13: "Intensive care medicine",
        value13: "Intensive care medicine",
        label14: "Surgery",
        value14: "Surgery",
        label15: "Dermatology",
        value15: "Dermatology",
        label16: "Anesthesiology",
        value16: "Anesthesiology",
        label17: "Neurosurgery",
        value17: "Neurosurgery",
        fieldName: "hospitalDepartments",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "hospitalServices",
        type: "multiSelect",
        placeholder: "HospitalServices",
        label: "HospitalServices",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "HospitalServices",
        derivedValue: "hospitalServices=hospitalServices=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalServices",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Cardiology", value: "Cardiology" },
          { label: "Pediatrics", value: "Pediatrics" },
          { label: "Physical therapy", value: "Physical therapy" },
          { label: "Emergency medicine", value: "Emergency medicine" },
          { label: "Radiology", value: "Radiology" },
          { label: "Ophthalmology", value: "Ophthalmology" },
          { label: "Anesthesiology", value: "Anesthesiology" },
        ],
        fieldType: "multiSelect",
        isMultiSelect: true,
        textAlign: "Center",
        show: true,
        field: "hospitalServices",
        showOrHideFields: [],
        label0: "Cardiology",
        value0: "Cardiology",
        label1: "Pediatrics",
        value1: "Pediatrics",
        label2: "Physical therapy",
        value2: "Physical therapy",
        label3: "Emergency medicine",
        value3: "Emergency medicine",
        label4: "Radiology",
        value4: "Radiology",
        label5: "Ophthalmology",
        value5: "Ophthalmology",
        label6: "Anesthesiology",
        value6: "Anesthesiology",
        fieldName: "hospitalServices",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        width: "150px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Email",
        derivedValue: "email=email=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "password",
        type: "password",
        placeholder: "Password",
        label: "Password",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Password",
        derivedValue: "password=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "password",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "password",
        showOrHideFields: [],
        fieldName: "password",
        fieldType: "password",
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        name: "hospitalId",
        type: "text",
        placeholder: "HospitalID",
        label: "HospitalID",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "HospitalID",
        derivedValue: "hospitalId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "hospitalId",
        showOrHideFields: [],
        fieldName: "hospitalId",
        fieldType: "text",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "hospitalWebsite",
        type: "WebSite",
        placeholder: "HospitalWebsite",
        label: "HospitalWebsite",
        width: "150px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "HospitalWebsite",
        derivedValue: "hospitalWebsite=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalWebsite",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "WebSite",
        textAlign: "Center",
        show: true,
        field: "hospitalWebsite",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.hospitals}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const customButton = ({item, tableItem}) => {
    return (
      <>
        {
          item?.fileName &&
          <a href={config.downloadUrl + item.fileName} download={item.fileName} style={{marginLeft: "10px"}}>
            <FontAwesomeIcon
                    className='genderIconAlignment'
                    color='#17a2b8'
                    icon='download'
                    data-toggle="tool-tip"
                    title='Download'
                    style={{ color: '#17a2b8', width: '15', cursor: 'pointer', }}
            />
          </a>
        }
      </>
    )
  }

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  return (
    <span>
      {stateOptions &&
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={false}
          PrintRequired={isEditPermission}
          // globalSearch={'Display Name/Email'}
          // type='Hospitals'
          // apiUrl={apiCalls.Hospitals}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.hospitals}
          globalSearch={"Practice Name/Address/City/State"}
          displayName="Practices"
          type="Practices"
          routeTo={apiCalls.hospitals}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          apiResponseKey={apiCalls.hospitals?.toLowerCase()}
          apiUrl={apiCalls.hospitals}
          selectedId={params.id}
          customActionButtons={customButton}
          addCustomValidations={addCustomValidations}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="hospitals"
          apiUrl={apiCalls.hospitals}
        />
      ) : null}
    </span>
  );
};

export default Hospitals;
